import { axiosInstance } from '../../shared';
import { serviceConfig } from '../../../config';
import { TelemetryData } from '../../../parts/Bookings/types';

async function getTelemetry(bookingId: string): Promise<TelemetryData> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}/gettelemetry/${bookingId}`;
  let telemetryData;

  try {
    const response = await axiosInstance.get(url);
    telemetryData = response.data;
  } catch (err) {
    throw new Error('Failed to get Telemetry Data.');
  }

  return telemetryData;
}
export default getTelemetry;
