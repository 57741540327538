import { useContext } from 'react';

import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  GlobalContext,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { LoadingMask, StatusDisk } from '../../components';
import { Booking } from '../../services/bookings/types';
import { formatDate, getMarketName } from '../shared/utils';
import { bookingStatusNames, TerminateAction } from './utils';
import { SelectedBookingContext } from '../../modules/Bookings/selectedBookingContext';
import { useSelectedBookingActions } from '../../modules/Bookings/selectedBookingReducer';
import { copyToClipboard } from '../../utils';
import { copyFormattedDetailsToClipboard } from '../shared/utils';
import HighlightBadge from './HighlightBadge';
import { SortColumnType } from '../../modules/Bookings';
import SortCell from '../../components/SortCell';
import { useUserContext } from '../../modules/Users/UserContext';
import { useState, useEffect } from 'react';

type TableProps = {
  data: Booking[];
  isLoading?: boolean;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  onPaginationNextChange: GenericFunction;
  onPaginationPreviousChange: GenericFunction;
  onColumnSortChange: GenericFunction;
  columnSort: SortColumnType;
};

function BookingsTable({ data, isLoading = false, onColumnSortChange, columnSort }: TableProps): JSX.Element {
  const { dispatch } = useContext(SelectedBookingContext);
  const { setSelectedBooking, openDetailsPanel, setIsBookingTermination } = useSelectedBookingActions(dispatch);
  const { alert } = useContext(GlobalContext);
  const hasData = data.length > 0;
  const fillerArray = new Array(5).fill(0).map(() => Math.random());

  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil((data?.length || 0) / perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const paginatedRecords = data?.slice(startIndex, endIndex) || [];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pageCount) {
      handlePageChange(currentPage + 1);
    }
  };

  function handleIndex() {
    if (currentPage > pageCount) setCurrentPage(1);
    setPerPage(20);
  }

  useEffect(() => {
    handleIndex();
  }, [data]);

  function handlePerPageSelectChange(value: string): void {
    setPerPage(Number(value));
    setCurrentPage(1);
  }

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleCopyAllDetailsToClipboard(booking: Booking) {
    copyFormattedDetailsToClipboard(booking, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleOpenBookingDetails(booking: Booking) {
    setSelectedBooking(booking);
    openDetailsPanel();
  }

  function handleTerminateBooking(booking: Booking) {
    setSelectedBooking(booking);
    setIsBookingTermination();
  }

  function hasAccess(permission: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(permission);
  }

  return (
    <div className="z-10 bookings-table mb-71">
      <div className="overflow-x-scroll">
        <Table extraClasses="min-w-max">
          <Thead extraClasses="!bg-neutral-50">
            {isLoading ? (
              <Tr>
                <Th>
                  <div className="pl-6 py-1.5">
                    <LoadingMask />
                  </div>
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
              </Tr>
            ) : (
              <Tr>
                <Th>
                  <span className="font-medium pl-6 text-sm text-neutral-400">STATUS</span>
                </Th>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <SortCell sortValue={columnSort.start} sortLabel="start" onClick={onColumnSortChange} />
                </Th>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <SortCell sortValue={columnSort.end} sortLabel="end" onClick={onColumnSortChange} />
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">LENDER</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">BORROWER</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">MARKET</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">MODEL</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">ORDER REFERENCE</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400">HIGHLIGHTED</span>
                </Th>
                <Th extraClasses="sr-only">Dropdown Menu</Th>
              </Tr>
            )}
          </Thead>
          <Tbody extraClasses="divide-y divide-gray-200">
            {isLoading
              ? fillerArray.map(_ => (
                  <Tr key={_}>
                    <Td>
                      <div className="pl-5 py-3">
                        <LoadingMask />
                      </div>
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                  </Tr>
                ))
              : paginatedRecords?.map(booking => (
                  <Tr key={booking.id}>
                    <Td>
                      <div className="text-gray-900 flex justify-start pl-6 items-center font-medium">
                        <StatusDisk status={booking.status} extraClasses="mr-2" />
                        {bookingStatusNames[booking.status]}
                      </div>
                    </Td>
                    <Td>{formatDate(booking.start)}</Td>
                    <Td>{formatDate(booking.extension?.endTime || booking.end)}</Td>
                    <Td>
                      <div className="flex items-center">
                        <Avatar
                          extraClasses="mr-1.5"
                          size="small"
                          alt={booking.lender.firstName}
                          src={booking.lender.profilePicture}
                        />
                        <span>{booking.lender.firstName}</span>
                      </div>
                    </Td>
                    <Td>
                      <div className="flex items-center">
                        <Avatar
                          size="small"
                          alt={booking.borrower.firstName}
                          src={booking.borrower.profilePicture}
                          extraClasses="mr-1.5"
                        />
                        <span>{booking.borrower.firstName}</span>
                      </div>
                    </Td>
                    <Td>{getMarketName(booking.vehicle.market)}</Td>
                    <Td>
                      <span>{booking.vehicle?.model}</span>
                    </Td>
                    <Td>{booking.orderReference}</Td>
                    <Td>
                      <HighlightBadge booking={booking} />
                    </Td>
                    <Td cellWidth={100} align="right">
                      <div className="flex items-center pr-2">
                        <Button
                          onClick={() => handleOpenBookingDetails(booking)}
                          variant="transparent"
                          size="small"
                          extraClasses="mr-2">
                          <Icon name="list_alt" size="small" variant="dark" />
                        </Button>
                        <Dropdown icon="more_vert" isBorderless label="">
                          <DropdownItem onClick={() => handleCopyAllDetailsToClipboard(booking)}>
                            <div className="flex justify-between items-center">
                              <span>All details</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.id)}>
                            <div className="flex justify-between items-center">
                              <span>Booking ID</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.orderReference)}>
                            <div className="flex justify-between items-center">
                              <span>Order Reference</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.borrower.snowflakeId)}>
                            <div className="flex justify-between items-center">
                              <span>Borrower user ID</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.lender.snowflakeId)}>
                            <div className="flex justify-between items-center">
                              <span>Lender user ID</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.vehicle.vin)}>
                            <div className="flex justify-between items-center">
                              <span>Vehicle ID (VIN)</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleCopyToClipboard(booking.vehicle.regNr)}>
                            <div className="flex justify-between items-center">
                              <span>Vehicle License Plate</span>
                              <Icon name="content_copy" />
                            </div>
                          </DropdownItem>
                          {hasAccess('FUNCTION_FORCE_END') && (
                            <TerminateAction
                              type="menuItem"
                              booking={booking}
                              onClick={() => handleTerminateBooking(booking)}
                            />
                          )}
                        </Dropdown>
                      </div>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {!isLoading && !hasData && <p className="text-center text-2xl m-8 text-gray-500">No bookings found</p>}
      </div>
      {!isLoading && hasData && (
        <div className="mt-4 flex justify-between p-6 border-t">
          <Button
            onClick={goToPreviousPage}
            extraClasses={`px-3 py-2 mx-1 rounded-md ${
              currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
            }`}
            isDisabled={currentPage === 1}
            variant="white">
            Previous Page
          </Button>
          <div>
            <label htmlFor="perPageSelect" className="text-gray-500">
              Records per page:{' '}
            </label>
            <select
              id="perPageSelect"
              className="w-16  p-2 border rounded-md bg-white"
              value={perPage}
              onChange={e => handlePerPageSelectChange(e.target.value)}>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={data?.length}>all</option>
            </select>
          </div>
          <div className="text-gray-500">
            Page {currentPage} of {pageCount}
          </div>
          <Button
            onClick={goToNextPage}
            extraClasses={`px-3 py-2 mx-1 rounded-md ${
              currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
            }`}
            isDisabled={currentPage === pageCount}
            variant="white">
            Next Page
          </Button>
        </div>
      )}
    </div>
  );
}

export default BookingsTable;
