import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { Booking, BookingsQuery } from './types';

async function getBookings(query: BookingsQuery): Promise<Booking[]> {
  const url = `${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.customerServiceBookings}`;
  let bookings;

  try {
    const response = await axiosInstance.get(url, {
      params: query,
    });
    bookings = response.data.bookings;
  } catch (err) {
    throw new Error('Failed to get bookings.');
  }

  return bookings;
}

export default getBookings;
