import { GlobalContext, Icon } from 'lynkco-up-core';
import { useContext, useEffect, useState } from 'react';
import getTelemetry from '../../services/bookings/telemetry/getTelemetry';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { formatDate } from '../shared/utils';
import { TelemetryData, TelemetryDataItem } from './types';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type TelemetryProps = {
  bookingId: string;
};
interface EntryCardProps {
  title: string;
  data: TelemetryDataItem[];
}

const LineChart: React.FC<EntryCardProps> = ({ data, title }) => {
  const labels = data.map(item => formatDate(item.timestamp));
  const values = data.map(item => item.value);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Line chart for ' + title,
        data: values,
        borderColor: 'rgb(20 185 166)',
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return <Line data={chartData} />;
};

const EntryCard: React.FC<EntryCardProps> = ({ title, data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const startValue = data ? 'Start Value: ' + data[0].value + ' at time ' + formatDate(data[0].timestamp) : 0;
  const endValue = data
    ? 'End Value: ' + data[data.length - 1].value + ' at time ' + formatDate(data[data.length - 1].timestamp)
    : 0;

  const handleToggleExpand = () => setIsExpanded(!isExpanded);
  const handleLoadMore = (value: boolean) => setShowAll(value);
  return (
    <div className="border rounded-lg shadow-lg w-full p-4 bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold">{title}</h2>
        <button onClick={handleToggleExpand} className="text-blue-600 hover:underline">
          {isExpanded ? <Icon name="keyboard_arrow_up" /> : <Icon name="keyboard_arrow_down" />}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-4 space-y-2">
          <LineChart data={data} title={title} />
          <button onClick={() => handleLoadMore(!showAll)} className="text-sm text-blue-600 hover:underline">
            {!showAll ? 'Load all values' : 'Only start and end'}
          </button>
          {!showAll ? (
            <>
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">{startValue}</span>
              </div>

              <div className="flex justify-between">
                <span className="font-medium text-gray-700">{endValue}</span>
              </div>
            </>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full table-auto border-collapse">
                <tbody>
                  {data?.map((entry, index) => (
                    <tr key={index} className="text-gray-800">
                      <td className="px-4 py-2">{formatDate(entry.timestamp)}</td>
                      <td className="px-4 py-2">{entry.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
function Telemetry({ bookingId }: TelemetryProps) {
  const [telemetryData, setTelemetryData] = useState<TelemetryData>({
    electricEntries: [],
    odometerEntries: [],
    fuelEntries: [],
  });
  const { alert } = useContext(GlobalContext);
  useEffect(() => {
    fetchTelemetryData();
  }, [bookingId]);
  async function fetchTelemetryData() {
    try {
      const fetchedTelemtryData: TelemetryData = await getTelemetry(bookingId);
      setTelemetryData(fetchedTelemtryData);
    } catch (error) {
      alert.show('Failed to fetch tele data:', 'error');
    }
  }
  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-8">
        {telemetryData?.electricEntries.length <= 0 &&
        telemetryData?.fuelEntries.length <= 0 &&
        telemetryData?.odometerEntries.length <= 0
          ? 'No Telemetry Data Reported for This Car'
          : null}
        {telemetryData?.electricEntries.length > 0 ? (
          <EntryCard title="Electric Entries" data={telemetryData?.electricEntries} />
        ) : null}
        {telemetryData?.fuelEntries.length > 0 ? (
          <EntryCard title="Fuel Entries" data={telemetryData?.fuelEntries} />
        ) : null}
        {telemetryData?.odometerEntries.length > 0 ? (
          <EntryCard title="Odometer Entries" data={telemetryData?.odometerEntries} />
        ) : null}
      </div>
    </div>
  );
}

export default Telemetry;
