import { Tabs } from 'lynkco-up-core';
import { Tab } from 'lynkco-up-core/dist/types/components/Tabs';

import { Booking } from '../../services/bookings/types';

import VehicleAndFuel from './bookingDetailsVehicleAndFuel';
import Overview from './bookingDetailsOverview';
import Ratings from './bookingDetailsRatings';
import Comments from './bookingDetailsComments';
import RawApiResponse from '../shared/DetailsRawApiResponse';
import Telemetry from './bookingDetailsTelemetry';

type BookingDetailsProps = {
  booking: Booking;
  onUserSearch: (customerId: string) => void;
};

function BookingDetails({ onUserSearch, booking }: BookingDetailsProps) {
  if (!booking) {
    return null;
  }

  const tabs: Tab[] = [
    {
      name: 'Overview',
      content: <Overview onUserSearch={onUserSearch} booking={booking} />,
      isActive: true,
    },
    {
      name: 'Vehicle & Fuel',
      content: <VehicleAndFuel booking={booking} />,
    },
    {
      name: 'Ratings',
      content: <Ratings booking={booking} />,
    },
    {
      name: 'Comments',
      content: <Comments booking={booking} />,
    },
    {
      name: 'Raw',
      content: <RawApiResponse data={booking} />,
    },
    {
      name: 'Telemetry',
      content: <Telemetry bookingId={booking.id} />,
    },
  ];

  return (
    <div>
      <h2 className="font-medium text-xl mb-2.5">Booking</h2>
      <Tabs tabsName="booking-details" tabs={tabs} />
    </div>
  );
}

export default BookingDetails;
